import { View, TouchableOpacity, ScrollView } from "react-native";
import { useRoute } from "@react-navigation/native";
import { io } from "socket.io-client";
import { useEffect, useState } from "react";

import Typography from "../../../components/typography";
import MatchDetails from "../../../components/matchDetails";
import TabBar from "../../../components/tabBar";
import Loader from "../../../components/loader";

import api, { apiBaseURL } from "../../../functions/api";

import styles from "./styles";
import HowTo from "../../../components/howTo";
import { useSelector } from "react-redux";
import { scale, verticalScale } from "react-native-size-matters";
import fonts from "../../../contants/fonts";

const Bat = () => {
  const [matchData, setMatchData] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [socket, setSocket] = useState(null);
  const [liveResult, setLiveResult] = useState(null);
  const [runRate, setRunRate] = useState(null);
  const [tabTitle, setTabTitle] = useState(null);
  const [bowlerResult, setBowlerResult] = useState([]);
  const [x, setX] = useState(null);
  const [showSm, setShowSm] = useState(false);

  const { _id, bettingTeam } = useRoute().params;

  const tabIndex = useSelector((s) => s.matchTab.value);

  const tabBarProps = {
    match: _id,
    matchData,
    liveResult,
    runRate,
    bowlerResult,
    x,
  };

  const getMatch = async () => {
    try {
      const [matchData] = await api.get("/match/" + _id, true);
      const liveScore = await api.get("/live-score/" + _id, true);
      matchData.score = liveScore;
      setMatchData(matchData);
      getLiveResult(matchData._id, matchData.bowler, matchData._over);
      getRunRate(matchData._id);
      // getBowlerResult(matchData._id, matchData._over);
    } catch (err) {
      console.log("🚀 ~ file: matchDetails.js:18 ~ getMatch ~ err:", err);
      setShowLoader(false);
    }
  };

  const getLiveResult = async (match, b, over) => {
    over = parseFloat(over);
    try {
      const data = await api.get(
        `/live-result?limit=${10}&match=` + match,
        true
      );
      setBowlerResult(
        data
          .filter(
            (i) =>
              i.bowler == b &&
              i._over <= over &&
              i._over > Math.floor(parseFloat(over))
          )
          .sort((a, b) => a._id - b._id)
      );
      setLiveResult(data);
      getX(match);
    } catch (err) {
      console.log("🚀 ~ file: index.js:46 ~ getLiveResult ~ err:", err);
    }
    setShowLoader(false);
  };

  const getRunRate = async (match) => {
    try {
      const data = await api.get(
        `/run-rate?match=${match}&team=${bettingTeam}`,
        true
      );
      setRunRate(data);
    } catch (err) {
      console.log("🚀 ~ file: index.js:58 ~ getRunRate ~ err:", err);
    }
  };

  const getX = async (match) => {
    try {
      const data = await api.get(`/x/${match}`);
      setX(data);
    } catch (err) {
      console.log("🚀 ~ getX ~ err:", err);
    }
  };

  const makeSocket = () => {
    const socket = io(apiBaseURL);
    setSocket(socket);
  };

  useEffect(() => {
    getMatch();
    makeSocket();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("result", getMatch);
      // setInterval(getMatch, 1000);
      socket.on("sessionStatusUpdate", getMatch);
    }
  }, [socket]);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Loader show={showLoader} />
      <MatchDetails
        data={matchData}
        bowlerResult={bowlerResult}
        x={x}
        runRate={runRate}
      />
      <HowTo show={showSm} onHide={setShowSm}>
        {tabIndex === 0 ? (
          <>
            <View>
              {/* <Img
                source={images.ptsPoster}
                style={{
                  width: '100%',
                  height: scale(130),
                  backgroundColor: colors.bg,
                }}
              /> */}
            </View>
            <View>
              <Typography
                title="Ball-to-Ball Bets: A Game of Precision and Timing"
                style={{
                  fontSize: scale(13),
                  marginTop: verticalScale(15),
                  fontWeight: fonts._700,
                }}
              />
              <Typography
                title="One of the standout features that sets Crickstox apart is its ball-to-ball betting option. Unlike traditional fantasy platforms where your predictions are based on the entire match, Crickstox allows you to place bets on specific deliveries. This level of granularity adds an extra layer of excitement, requiring users to analyze the game situation, player form, and pitch conditions with precision."
                style={{ marginTop: scale(5) }}
              />

              <Typography
                title="Imagine the thrill of placing a bet on whether the next ball will be a dot, a single, or even a boundary. Crickstox`s ball-to-ball bets keep users engaged throughout the match, turning every delivery into a potential win or loss. It`s a feature that not only tests your cricketing knowledge but also your ability to read the game in real-time."
                style={{ marginTop: scale(10) }}
              />
            </View>
          </>
        ) : tabIndex === 2 ? (
          <>
            <View>
              {/* <Img
                source={images.ptsPoster}
                style={{
                  width: '100%',
                  height: scale(130),
                  backgroundColor: colors.bg,
                }}
              /> */}
            </View>
            <View>
              <Typography
                title="Buy Players: Your Team, Your Choices"
                style={{
                  fontSize: scale(13),
                  marginTop: verticalScale(15),
                  fontWeight: fonts._700,
                }}
              />
              <Typography
                title="CrickStox takes the fantasy cricket experience to the next level with its player auction feature. Instead of being restricted to a fixed budget, users have the opportunity to bid for their favorite players in a dynamic auction environment. This adds an element of strategy and competition, as users must carefully manage their budget to assemble the best possible team."
                style={{ marginTop: verticalScale(5) }}
              />

              <Typography
                title="The buy players feature brings a sense of ownership to fantasy cricket, allowing users to create a team that reflects their cricketing insights and instincts. It's not just about selecting star players; it's about making shrewd choices, finding undervalued gems, and building a team that can outperform the competition. CrickStox's buy players feature turns fantasy cricket into a true test of managerial skills."
                style={{ marginTop: verticalScale(10) }}
              />
            </View>
          </>
        ) : tabIndex === 4 ? (
          <>
            <View>
              {/* <Img
                source={images.ptsPoster}
                style={{
                  width: '100%',
                  height: scale(130),
                  backgroundColor: colors.bg,
                }}
              /> */}
            </View>
            <View>
              <Typography
                title="Run Rate Bet: Predicting the Momentum Swings"
                style={{
                  fontSize: scale(13),
                  marginTop: scale(15),
                  fontWeight: fonts._700,
                }}
              />
              <Typography
                title="Cricket is a game of momentum, and predicting the run rate is a key aspect of understanding the flow of a match. CrickStox incorporates a unique run rate betting feature, where users can place bets on the expected run rate for specific periods of the game. This could be an over-by-over prediction or a cumulative run rate for a particular innings."
                style={{ marginTop: scale(5) }}
              />

              <Typography
                title="The run rate bet adds an extra dimension to the fantasy cricket experience, requiring users to analyze the batting and bowling performances, the state of the pitch, and the strategies employed by the teams. Whether you're expecting a flurry of boundaries in the powerplay or a tight bowling spell, CrickStox's run rate bet feature allows you to immerse yourself in the ebb and flow of the game."
                style={{ marginTop: scale(10) }}
              />
            </View>
          </>
        ) : tabIndex === 3 ? (
          <>
            <View>
              {/* <Img
                source={images.ptsPoster}
                style={{
                  width: '100%',
                  height: scale(130),
                  backgroundColor: colors.bg,
                }}
              /> */}
            </View>
            <View>
              <Typography
                title="4. Head-to-Head One vs. One Player Contests: Direct Duels for Glory"
                style={{
                  fontSize: scale(13),
                  marginTop: scale(15),
                  fontWeight: fonts._700,
                }}
              />
              <Typography
                title="For those seeking a more personalized and intense experience, CrickStox offers head-to-head contests where users can go one-on-one against each other. This format adds a competitive edge to fantasy cricket, as users battle it out in a direct duel to prove their cricketing expertise. It's not just about scoring points; it's about outsmarting and outplaying your opponent."
                style={{ marginTop: scale(5) }}
              />

              <Typography
                title="The head-to-head contests on CrickStox create a dynamic and thrilling environment, where every decision matters. From player selection to strategic bets, users must be at the top of their game to emerge victorious. Whether you're competing against friends or challenging random opponents, the head-to-head contests add a social and competitive element to the fantasy cricket experience."
                style={{ marginTop: scale(10) }}
              />
            </View>
          </>
        ) : tabIndex === 1 ? (
          <>
            <View>
              {/* <Img
                source={images.ptsPoster}
                style={{
                  width: '100%',
                  height: scale(130),
                  backgroundColor: colors.bg,
                }}
              /> */}
            </View>
            <View>
              <Typography
                title="Sessions: Betting on Cricket Phases"
                style={{
                  fontSize: scale(13),
                  marginTop: scale(15),
                  fontWeight: fonts._700,
                }}
              />
              <Typography
                title="CrickStox introduces the concept of sessions, allowing users to place bets on specific phases of the match. This could be predicting the number of wickets to fall in the powerplay or anticipating the run total in the middle overs. The sessions feature enhances the overall engagement, turning every part of the match into a potential betting opportunity."
                style={{ marginTop: scale(5) }}
              />

              <Typography
                title="Sessions betting requires users to have a deep understanding of the game's dynamics, as different phases demand different strategies. Whether you fancy a batting collapse in the death overs or expect a bowler's dominance in the powerplay, CrickStox's sessions feature invites users to explore various facets of cricket and make predictions that align with their insights."
                style={{ marginTop: scale(10) }}
              />
            </View>
          </>
        ) : null}
      </HowTo>
      {tabIndex < 5 && (
        <View style={styles.playTime}>
          <Typography
            title={tabTitle || "Every Ball To Ball"}
            style={styles.text}
          />
          <TouchableOpacity onPress={() => setShowSm(true)}>
            <Typography title="How To Play?" style={styles.howTo} />
          </TouchableOpacity>
        </View>
      )}
      <TabBar
        {...tabBarProps}
        onChangeTab={({ title }) => setTabTitle(title)}
      />
    </ScrollView>
  );
};

export default Bat;
