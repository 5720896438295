import {
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  SectionList,
  RefreshControl,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";

import icons from "../contants/icons";
import fonts from "../contants/fonts";

import Typography from "./typography";
import Img from "./image";
import BetConfirm from "./betConfirm";

import api, { apiBaseURL, scoreBaseURL } from "../functions/api";
import toast from "../functions/toast";
import Loader from "./loader";
import { useSelector } from "react-redux";
import colors from "../contants/colors";
import { scale, verticalScale } from "react-native-size-matters";
import orderBy from "lodash.orderby";
import { io } from "socket.io-client";
import gifs from "../contants/gifs";
import axios from "axios";
import Placeholder from "./placeholder";

const { width } = Dimensions.get("window");

const Match = ({ status }) => {
  const [matches, setMatches] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [match, setMatch] = useState(null);
  const [teamShortName, setTeamShortName] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [sliderPage, setSliderPage] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [socket, setSocket] = useState(null);
  const [myX, setMyX] = useState(1);

  // const isFocused = useIsFocused();

  const sliderRef = useRef();

  const user = useSelector((s) => s.user.value);

  const { navigate } = useNavigation();

  const groupByDate = (array) => {
    const groupedByDate = {};

    array.forEach((item) => {
      const startDate = new Date(item.startTime).toISOString().split("T")[0];

      if (!groupedByDate[startDate]) {
        groupedByDate[startDate] = [];
      }

      groupedByDate[startDate].push(item);
    });

    return groupedByDate;
  };

  const formatTimestamp = (timestamp) => {
    const inputFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
    const date = moment(timestamp, inputFormat);

    const duration = moment.duration(date.diff(moment()));

    if (duration.asSeconds() < 60) {
      return `${Math.floor(duration.asSeconds())}s`;
    } else if (duration.asMinutes() < 60) {
      return `${Math.floor(duration.asMinutes())}m: ${Math.floor(
        duration.seconds()
      )}s`;
    } else if (duration.asHours() < 48) {
      return `${Math.floor(duration.asHours())}h: ${Math.floor(
        duration.minutes()
      )}m`;
    } else {
      return date.format("DD MMM YYYY");
    }
  };

  const getX = async () => {
    // setShowLoader(true);
    const dataa = [...data];
    if (dataa && dataa.length > 0) {
      const prom = dataa.map(async ({ _id }, i) => {
        const score = await api.get("/x/" + _id);
        dataa[i].x = score;
        return dataa[i];
      });
      await Promise.all(prom);
      let _data = groupByDate(dataa);
      const keys = Object.keys(_data);
      const arr = [];
      keys.forEach((it, i) => {
        arr[i] = { title: it, data: _data[it] };
      });
      setMatches(
        orderBy(arr, "title", status === "completed" ? "desc" : "asc")
      );
      setLoaded(true);
      setShowLoader(false);
    }
  };

  const setM = async () => {
    const dataa = [...data];
    if (dataa && dataa.length > 0) {
      if (status !== "upcoming") {
        const prom = dataa.map(async ({ _id }, i) => {
          const score = await api.get("/live-score/" + _id, true);
          dataa[i].score = score;
          return dataa[i];
        });
        await Promise.all(prom);
        let _data = groupByDate(dataa);
        const keys = Object.keys(_data);
        const arr = [];
        keys.forEach((it, i) => {
          arr[i] = { title: it, data: _data[it] };
        });
        setMatches(
          orderBy(arr, "title", status === "completed" ? "desc" : "asc")
        );
        status === "completed" && setLoaded(true);
      } else {
        let _data = groupByDate(dataa);
        const keys = Object.keys(_data);
        const arr = [];
        keys.forEach((it, i) => {
          arr[i] = { title: it, data: _data[it] };
        });
        setMatches(
          orderBy(arr, "title", status === "completed" ? "desc" : "asc")
        );
        // status !== 'completed' && getX();
        // status == 'completed' && getAmount();
        // status === 'live' && checkMatchStatus();
        // setLoaded(true);
      }
    }
  };

  const getAmount = async () => {
    const dataa = [...data];
    try {
      const prom = dataa.map(async (item, index) => {
        const { amount } = await api.get(
          `/winning-amount?match=${item._id}&user=${user}`
        );
        dataa[index].amount = amount;
        return dataa[index];
      });
      await Promise.all(prom);
      let _data = groupByDate(dataa);
      const keys = Object.keys(_data);
      const arr = [];
      keys.forEach((it, i) => {
        arr[i] = { title: it, data: _data[it] };
      });
      setMatches(
        orderBy(arr, "title", status === "completed" ? "desc" : "asc")
      );
      setData(_data);
    } catch (err) {
      console.log("🚀 ~ getAmount ~ err:", err);
    }
  };

  const addBet = async (amount) => {
    setShowLoader(true);
    try {
      const prepData = {
        amount,
        _match: match,
        selectedTeam,
        user,
        x: myX,
      };

      await api.post("/add-match-bet", prepData);

      setShowModal(false);
      toast("Bet added successfully");
    } catch (err) {
      console.log("🚀 ~ file: matchDetails.js:22 ~ addBet ~ err:", err);
    }
    setShowLoader(false);
  };

  const cap = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const getMatches = async (p) => {
    try {
      const res = await api.get(
        `/matches?status=${cap(status)}&page=${p || page}&numOfItem=${6}${
          status === "completed" ? "&desc=yes" : ""
        }`,
        true
      );
      p === 1 || page === 1 ? setData(res) : setData([...data, ...res]);
      p === 1 && setPage(1);
      setIsLoading(res.length >= 6);
    } catch (err) {
      console.log("🚀 ~ file: index.js:55 ~ getMatches ~ err:", err);
    }
    setShowLoader(false);
  };

  const checkMatchStatus = async () => {
    // setShowLoader(true);
    const dataa = [...data];
    if (dataa && dataa.length > 0) {
      const prom = dataa.map(async ({ _id }, i) => {
        const {
          data: { result },
        } = await axios.get(scoreBaseURL + "/is-match-live/" + _id);

        dataa[i].matchStatus = result;
        return dataa[i];
      });
      await Promise.all(prom);
      let _data = groupByDate(dataa);
      const keys = Object.keys(_data);
      const arr = [];
      keys.forEach((it, i) => {
        arr[i] = { title: it, data: _data[it] };
      });
      setMatches(
        orderBy(arr, "title", status === "completed" ? "desc" : "asc")
      );
      setLoaded(true);
      setShowLoader(false);
    }
  };

  const makeSocket = () => {
    const socket = io(apiBaseURL);
    setSocket(socket);
  };

  useEffect(() => {
    getMatches();
  }, [page]);

  useEffect(() => {
    setM();
    setIsRefreshing(false);
    if (status !== "completed") getX();
    if (status === "completed") getAmount();
    if (status === "live") checkMatchStatus();
    if (status === "upcoming") {
      const timer = setInterval(setM, 1000);
      return () => clearInterval(timer);
    }
  }, [data]);

  useEffect(() => {
    const scrollNext = () => {
      if (sliderRef.current) {
        sliderRef.current.scrollTo({
          animated: true,
          x: Math.round(scroll / width) === 3 ? 0 : scroll + width,
        });
      }
    };

    const t = setTimeout(scrollNext, 3000);
    return () => clearTimeout(t);
  }, [scroll]);

  useEffect(() => {
    makeSocket();
  }, []);

  useEffect(() => {
    if (socket && status !== "completed") {
      // setInterval(() => {
      //   getMatches(1);
      // }, 1000);
      socket.on("result", () => {
        setShowModal(false);
        getMatches(1);
      });
    }
  }, [socket, status]);

  return (
    <ScrollView
      style={styles.container}
      nestedScrollEnabled
      scrollEnabled
      showsVerticalScrollIndicator={false}
      onScroll={({ nativeEvent }) => {
        const { layoutMeasurement, contentOffset, contentSize } = nativeEvent;
        const isCloseToBottom =
          layoutMeasurement.height + contentOffset.y >=
          contentSize.height - 100;

        if (isCloseToBottom) setPage(page + 1);
      }}
      refreshControl={
        <RefreshControl
          colors={[colors.btn]}
          refreshing={false}
          onRefresh={() => {
            setLoaded(false);
            getMatches(1);
          }}
        />
      }
    >
      <Loader show={showLoader && matches.length > 0} />
      {status === "upcoming" && (
        <View
          style={{
            marginTop: verticalScale(20),
          }}
        >
          <ScrollView
            horizontal
            pagingEnabled
            scrollEventThrottle={16}
            showsHorizontalScrollIndicator={false}
            ref={sliderRef}
            onScroll={({ nativeEvent }) => {
              const p = Math.round(nativeEvent.contentOffset.x / (width - 40));
              setSliderPage(p);
              setScroll(nativeEvent.contentOffset.x);
            }}
          >
            {[
              { img: "https://i.postimg.cc/L6ZXkg0f/banner.png" },
              { img: "https://i.postimg.cc/MHPrFQrS/20240212-235300-0000.png" },
              { img: "https://i.postimg.cc/44BMdD4S/20240213-002440-0000.png" },
              { img: "https://i.postimg.cc/qMcgTsBv/20240304-115950-0000.png" },
            ].map((item) => (
              <View style={styles.imageContainer}>
                <TouchableOpacity>
                  <Img
                    source={{ uri: item.img }}
                    style={styles.image}
                    resizeMode="cover"
                  />
                </TouchableOpacity>
              </View>
            ))}
          </ScrollView>
          <View style={styles.pagination}>
            {new Array(4).fill().map((_, i) => (
              <View
                style={
                  i === sliderPage ? styles.pageActive : styles.pageInactive
                }
              />
            ))}
          </View>
        </View>
      )}

      {showLoader ? (
        <View style={{ marginTop: verticalScale(20) }}>
          <Placeholder
            heigh={verticalScale(160)}
            item={5}
            padding={scale(20)}
          />
        </View>
      ) : (
        <>
          <BetConfirm
            show={showModal}
            hideModal={() => setShowModal(false)}
            min={100}
            val={"100"}
            onChange={addBet}
            x={myX}
            selected={teamShortName}
          />

          <SectionList
            sections={matches}
            style={{ paddingHorizontal: scale(10) }}
            contentContainerStyle={styles.flatList}
            showsVerticalScrollIndicator={false}
            // scrollEnabled={false}
            renderItem={({ item, index }) => {
              item.x = item.x || {
                teamA: 0,
                teamB: 0,
                teamALocked: 1,
                teamBLocked: 1,
              };
              item.amount = item.amount || 0;
              item.score = item.score || { teams: { teamA: {}, teamB: {} } };
              return (
                <>
                  {(status === "live" ? item.score : true) && (
                    <View style={styles.matches}>
                      <TouchableOpacity
                        style={[styles.ls, { width: width - scale(180) }]}
                        disabled={status !== "live"}
                        onPress={() => {
                          navigate("Bat", {
                            _id: item._id,
                            bettingTeam: item[item.bettingTeam],
                          });
                        }}
                      >
                        <Typography
                          title={item.tournamentName + ", " + item.ground}
                          style={styles.title}
                          lines={1}
                        />
                        {status === "upcoming" ? (
                          <TouchableOpacity
                            style={styles.teams}
                            onPress={() => {
                              navigate("Bat", {
                                _id: item._id,
                                tabIndex: 1,
                                teamA: item.teamAShortName,
                                teamB: item.teamBShortName,
                              });
                            }}
                          >
                            <View style={styles.team}>
                              <Img
                                source={{ uri: scoreBaseURL + item.teamAImage }}
                                style={styles.indIcon}
                              />
                              <Typography
                                title={item.teamAShortName}
                                style={styles.upcomingName}
                              />
                            </View>
                            <View style={styles.team}>
                              <Img
                                source={{ uri: scoreBaseURL + item.teamBImage }}
                                style={styles.indIcon}
                              />
                              <Typography
                                title={item.teamBShortName}
                                style={styles.upcomingName}
                              />
                            </View>
                          </TouchableOpacity>
                        ) : status === "live" ? (
                          <View style={styles.teams}>
                            <View style={styles.team}>
                              <Img
                                source={{ uri: scoreBaseURL + item.teamAImage }}
                                style={styles.indIcon}
                              />
                              <Typography
                                title={item.teamAShortName}
                                style={styles.name}
                              />
                              {item.bettingTeam === "teamA" ||
                              (item.score &&
                                item.score.teams.teamA.over !== "0.0") ? (
                                <Typography
                                  title={`${
                                    (item.score &&
                                      item.score.teams.teamA.run) ||
                                    ""
                                  }-${
                                    (item.score &&
                                      item.score.teams.teamA.wickets) ||
                                    ""
                                  } (${
                                    (item.score &&
                                      item.score.teams.teamA.over) ||
                                    ""
                                  })`}
                                  style={styles.name}
                                />
                              ) : (
                                <Typography
                                  title="Yet To Bat"
                                  style={styles.batting}
                                />
                              )}
                              {item.bettingTeam === "teamA" && (
                                <Img
                                  source={icons.batBall}
                                  style={{
                                    marginLeft: scale(5),
                                    width: scale(12),
                                  }}
                                />
                              )}
                            </View>
                            {/* team b */}
                            <View style={styles.team}>
                              <Img
                                source={{ uri: scoreBaseURL + item.teamBImage }}
                                style={styles.indIcon}
                              />
                              <Typography
                                title={item.teamBShortName}
                                style={styles.name}
                              />
                              {item.bettingTeam === "teamB" ||
                              (item.score &&
                                item.score.teams.teamB.over !== "0.0") ? (
                                <Typography
                                  title={
                                    item.score &&
                                    `${item.score.teams.teamB.run || ""}-${
                                      (item.score &&
                                        item.score.teams.teamB.wickets) ||
                                      ""
                                    } (${item.score.teams.teamB.over || ""})`
                                  }
                                  style={styles.name}
                                />
                              ) : (
                                <Typography
                                  title="Yet To Bat"
                                  style={styles.batting}
                                />
                              )}
                              {item.bettingTeam === "teamB" && (
                                <Img
                                  source={icons.batBall}
                                  style={{
                                    marginLeft: scale(5),
                                    width: scale(12),
                                  }}
                                />
                              )}
                            </View>
                          </View>
                        ) : status === "completed" ? (
                          <View style={styles.teams}>
                            <View
                              style={[
                                styles.team,
                                {
                                  opacity: item.teamA == item.winner ? 1 : 0.5,
                                },
                              ]}
                            >
                              <Img
                                source={{ uri: scoreBaseURL + item.teamAImage }}
                                style={styles.indIcon}
                              />
                              <Typography
                                title={item.teamAShortName}
                                style={styles.name}
                              />
                              {item.bettingTeam === "teamA" ||
                              (item.score &&
                                item.score.teams.teamA.over !== "0.0") ? (
                                <Typography
                                  title={`${
                                    item.score && item.score.teams.teamA.run
                                  }-${
                                    item.score && item.score.teams.teamA.wickets
                                  } (${
                                    item.score && item.score.teams.teamA.over
                                  })`}
                                  style={styles.name}
                                />
                              ) : (
                                <Typography
                                  title="Yet To Bat"
                                  style={styles.batting}
                                />
                              )}
                              {item.bettingTeam === "teamA" && (
                                <Img
                                  source={icons.batBall}
                                  style={{
                                    marginLeft: scale(5),
                                    width: scale(12),
                                  }}
                                />
                              )}
                            </View>
                            {/* team b */}
                            <View
                              style={[
                                styles.team,
                                {
                                  opacity: item.teamB == item.winner ? 1 : 0.5,
                                },
                              ]}
                            >
                              <Img
                                source={{ uri: scoreBaseURL + item.teamBImage }}
                                style={styles.indIcon}
                              />
                              <Typography
                                title={item.teamBShortName}
                                style={styles.name}
                              />
                              {item.bettingTeam === "teamB" ||
                              (item.score &&
                                item.score.teams.teamB.over !== "0.0") ? (
                                <Typography
                                  title={
                                    item.score &&
                                    `${item.score.teams.teamB.run}-${
                                      item.score &&
                                      item.score.teams.teamB.wickets
                                    } (${item.score.teams.teamB.over})`
                                  }
                                  style={styles.name}
                                />
                              ) : (
                                <Typography
                                  title="Yet To Bat"
                                  style={styles.batting}
                                />
                              )}
                              {item.bettingTeam === "teamB" && (
                                <Img
                                  source={icons.batBall}
                                  style={{
                                    marginLeft: scale(5),
                                    width: scale(12),
                                  }}
                                />
                              )}
                            </View>
                          </View>
                        ) : null}

                        {item.note && (
                          <Typography
                            title={
                              status === "completed"
                                ? `${
                                    item.teamA == item.winner
                                      ? item.teamAShortName
                                      : item.teamBShortName
                                  } won by ${
                                    item.teamA == item.winner
                                      ? item.score.teams.teamA.run -
                                        item.score.teams.teamB.run
                                      : item.score.teams.teamB.run -
                                        item.score.teams.teamA.run
                                  } Run`
                                : item.note
                            }
                            style={styles.footer}
                          />
                        )}
                      </TouchableOpacity>
                      <View style={styles.rs}>
                        {status === "upcoming" ? (
                          <View style={styles.startTime}>
                            <Typography
                              title="Starting In:"
                              style={styles.startIn}
                            />
                            <Typography
                              title={formatTimestamp(item.startTime)}
                              style={styles.time}
                            />
                          </View>
                        ) : null}
                        {status === "live" ? (
                          <View style={styles.startTime}>
                            <Img source={gifs.live} style={styles.liveIcon} />
                          </View>
                        ) : null}

                        {status === "live" ? (
                          <View style={[styles.bets, styles.bet]}>
                            <TouchableOpacity
                              style={[
                                styles.up,
                                { opacity: item.x.teamALocked == 0 ? 1 : 0.4 },
                              ]}
                              onPress={() => {
                                setMyX(item.x.teamA);
                                setSelectedTeam(item.teamA);
                                setShowModal(true);
                                setMatch(item._id);
                                setTeamShortName(item.teamAShortName);
                              }}
                              disabled={item.x.teamALocked == 1}
                            >
                              <Typography
                                title={item.teamAShortName}
                                style={styles.ru}
                              />
                              <Typography
                                title={item.x.teamA + " "}
                                style={styles.ru}
                              />
                              <Img
                                source={
                                  parseFloat(item.x.teamB).toFixed(3) <
                                  parseFloat(item.x.teamA).toFixed(3)
                                    ? icons.green
                                    : icons.red
                                }
                                style={{
                                  ...styles.down,
                                  ...{
                                    marginBottom:
                                      parseFloat(item.x.teamB).toFixed(3) <
                                      parseFloat(item.x.teamA).toFixed(3)
                                        ? 5
                                        : 2,
                                  },
                                }}
                              />
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={[
                                styles.up,
                                { opacity: item.x.teamBLocked == 0 ? 1 : 0.4 },
                              ]}
                              onPress={() => {
                                setMyX(item.x.teamB);
                                setSelectedTeam(item.teamB);
                                setShowModal(true);
                                setMatch(item._id);
                                setTeamShortName(item.teamBShortName);
                              }}
                              disabled={item.x.teamBLocked == 1}
                            >
                              <Typography
                                title={item.teamBShortName}
                                style={styles.ru}
                              />
                              <Typography
                                title={item.x.teamB + " "}
                                style={styles.ru}
                              />
                              <Img
                                source={
                                  parseFloat(item.x.teamA).toFixed(3) <
                                  parseFloat(item.x.teamB).toFixed(3)
                                    ? icons.green
                                    : icons.red
                                }
                                style={{
                                  ...styles.down,
                                  ...{
                                    marginBottom:
                                      parseFloat(item.x.teamA).toFixed(3) <
                                      parseFloat(item.x.teamB).toFixed(3)
                                        ? 5
                                        : 2,
                                  },
                                }}
                              />
                            </TouchableOpacity>
                          </View>
                        ) : status === "upcoming" ? (
                          <View style={styles.bets}>
                            <TouchableOpacity
                              style={[
                                styles.up,
                                { opacity: item.x.teamALocked == 0 ? 1 : 0.4 },
                              ]}
                              onPress={() => {
                                setMyX(item.x.teamA);
                                setSelectedTeam(item.teamA);
                                setShowModal(true);
                                setMatch(item._id);
                                setTeamShortName(item.teamAShortName);
                              }}
                              disabled={item.x.teamALocked == 1}
                            >
                              <Typography
                                title={item.teamAShortName}
                                style={styles.ru}
                              />
                              <Typography
                                title={item.x.teamA + " "}
                                style={styles.ru}
                              />
                              <Img
                                source={
                                  parseFloat(item.x.teamB).toFixed(3) <
                                  parseFloat(item.x.teamA).toFixed(3)
                                    ? icons.green
                                    : icons.red
                                }
                                style={{
                                  ...styles.down,
                                  ...{
                                    marginBottom:
                                      parseFloat(item.x.teamB).toFixed(3) <
                                      parseFloat(item.x.teamA).toFixed(3)
                                        ? 5
                                        : 2,
                                  },
                                }}
                              />
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={[
                                styles.up,
                                { opacity: item.x.teamBLocked == 0 ? 1 : 0.4 },
                              ]}
                              onPress={() => {
                                setMyX(item.x.teamB);
                                setSelectedTeam(item.teamB);
                                setShowModal(true);
                                setMatch(item._id);
                                setTeamShortName(item.teamBShortName);
                              }}
                              disabled={item.x.teamBLocked == 1}
                            >
                              <Typography
                                title={item.teamBShortName}
                                style={styles.ru}
                              />
                              <Typography
                                title={item.x.teamB + " "}
                                style={styles.ru}
                              />
                              <Img
                                source={
                                  parseFloat(item.x.teamA).toFixed(3) <
                                  parseFloat(item.x.teamB).toFixed(3)
                                    ? icons.green
                                    : icons.red
                                }
                                style={{
                                  ...styles.down,
                                  ...{
                                    marginBottom:
                                      parseFloat(item.x.teamA).toFixed(3) <
                                      parseFloat(item.x.teamB).toFixed(3)
                                        ? 5
                                        : 2,
                                  },
                                }}
                              />
                            </TouchableOpacity>
                          </View>
                        ) : status === "completed" ? (
                          <View
                            style={[
                              styles.bets,
                              styles.indWon,
                              { paddingLeft: scale(5) },
                            ]}
                          >
                            <View style={styles.win}>
                              <Img source={icons.worldCup} style={styles.cup} />
                              <View style={styles.wonTeam}>
                                <Img
                                  source={{
                                    uri:
                                      scoreBaseURL +
                                      (item.teamA == item.winner
                                        ? item.teamAImage
                                        : item.teamBImage),
                                  }}
                                  style={styles.wonInd}
                                />
                                <Typography
                                  title={`${
                                    item.teamA == item.winner
                                      ? item.teamAShortName
                                      : item.teamBShortName
                                  } WON`}
                                  style={styles.wonName}
                                />
                              </View>
                              {item.amount > 0 && (
                                <View>
                                  <Typography
                                    title={"You Won " + item.amount}
                                    style={{
                                      fontSize: scale(9),
                                      color: colors.engGreen,
                                    }}
                                  />
                                </View>
                              )}
                            </View>
                          </View>
                        ) : null}
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: verticalScale(7.5),
                          marginBottom: verticalScale(-2),
                        }}
                      >
                        <TouchableOpacity
                          style={styles.liveBtn}
                          onPress={() => {
                            if (status === "completed" || item.matchStatus)
                              return navigate("Bat", {
                                _id: item._id,
                                bettingTeam: item[item.bettingTeam],
                                tabIndex: 2,
                                teamA: item.teamAShortName,
                                teamB: item.teamBShortName,
                              });

                            navigate("BuyP", {
                              match: item._id,
                              index: 0,
                              status,
                              teamA: item.teamAShortName,
                              teamB: item.teamBShortName,
                            });
                          }}
                        >
                          <Typography
                            title={
                              status === "upcoming"
                                ? "BUY PLAYER"
                                : "VIEW BUY PLAYER"
                            }
                            style={styles.btnText}
                          />
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={[styles.liveBtn, { marginLeft: scale(10) }]}
                          onPress={() => {
                            if (status === "completed" || item.matchStatus)
                              return navigate("Bat", {
                                _id: item._id,
                                bettingTeam: item[item.bettingTeam],
                                tabIndex: 3,
                              });
                            navigate("BuyP", {
                              match: item._id,
                              index: 1,
                              status: status,
                              teamA: item.teamAShortName,
                              teamB: item.teamBShortName,
                            });
                          }}
                        >
                          <Typography
                            title={
                              status === "upcoming"
                                ? "PLAY CONTEST"
                                : "MY CONTEST"
                            }
                            style={styles.btnText}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </>
              );
            }}
            renderSectionHeader={({ section: { title } }) => (
              <Typography
                title={moment(title).format("D MMM YYYY")}
                style={styles.date}
              />
            )}
          />

          {matches && isLoading && matches.length > 6 && (
            <ActivityIndicator
              size={50}
              color={colors.white}
              style={{ marginVertical: 20 }}
            />
          )}
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    // paddingHorizontal: 15,
  },
  imageContainer: {
    width: width,
    paddingHorizontal: scale(20),
    alignItems: "center",
  },
  image: {
    width: width - scale(50),
    height: (width - scale(50)) / 2.5,
    borderRadius: scale(10),
  },
  pagination: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: scale(10),
  },
  pageInactive: {
    width: scale(7),
    height: scale(7),
    borderRadius: 100,
    backgroundColor: colors.grey,
    marginHorizontal: scale(2.5),
  },
  pageActive: {
    width: scale(7),
    height: scale(7),
    borderRadius: 100,
    backgroundColor: colors.white,
    marginHorizontal: scale(2.5),
  },
  matches: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#122940CC",
    // padding: scale(20),
    paddingVertical: verticalScale(10),
    paddingHorizontal: scale(20),
    borderRadius: scale(10),
    flexWrap: "wrap",
    marginTop: verticalScale(20),
  },
  ls: {
    width: width - scale(190),
  },
  btnText: {
    fontSize: scale(9),
    fontWeight: fonts._600,
    color: colors.white,
    letterSpacing: scale(0.5),
  },
  rs: {
    width: scale(120),
    alignItems: "center",
  },
  teams: {
    marginTop: verticalScale(20),
  },
  liveIcon: {
    height: scale(16),
  },
  date: {
    marginTop: verticalScale(10),
    fontSize: scale(11.5),
    fontWeight: fonts._600,
    marginBottom: verticalScale(-10),
    marginLeft: scale(15),
  },

  title: {
    fontSize: scale(9),
    color: "#F3F3F3",
    fontWeight: fonts._400,
    width: scale(160),
  },
  team: {
    flexDirection: "row",
    marginTop: verticalScale(5),
    alignItems: "center",
  },
  bets: {
    borderLeftColor: "#FFFFFF33",
    borderLeftWidth: 1,
    paddingLeft: scale(12),
  },
  indIcon: {
    width: scale(24),
    height: scale(24),
    borderRadius: 50,
  },
  name: {
    marginLeft: scale(7),
    fontSize: scale(11.5),
    fontWeight: fonts._600,
  },
  upcomingName: {
    marginLeft: scale(13),
    fontSize: scale(13),
    color: "#F0F0F0",
  },
  liveName: {
    marginLeft: scale(15),
    fontSize: scale(14),
    color: "#BDC3C7",
  },
  batting: {
    marginLeft: scale(7),
    fontSize: scale(14),
    color: "#F1C40F",
  },
  footer: {
    color: "#F1C40F",
    fontSize: scale(10),
    marginTop: verticalScale(10),
    fontWeight: fonts._600,
  },
  titleTime: {
    flexDirection: "row",
  },
  startTime: {
    alignItems: "center",
    marginLeft: scale(20),
  },
  startIn: {
    fontSize: scale(9),
  },
  time: {
    fontSize: scale(11.5),
    color: "#F1C40F",
    fontWeight: fonts._600,
    marginTop: verticalScale(3),
  },

  up: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: verticalScale(7.5),
    backgroundColor: "#060E24",
    paddingHorizontal: scale(10),
    borderRadius: scale(20),
    borderWidth: scale(1),
    borderColor: "#FFFFFF66",
    justifyContent: "center",
    paddingVertical: scale(3),
  },
  ru: {
    marginHorizontal: scale(4),
    fontWeight: fonts._600,
    fontSize: scale(10),
    paddingVertical: scale(2),
  },
  cup: {
    height: scale(40),
    width: scale(40),
  },
  wonTeam: {
    flexDirection: "row",
    alignItems: "center",
    width: scale(90),
    marginTop: verticalScale(7),
    justifyContent: "center",
  },
  wonName: {
    marginLeft: scale(3),
    fontWeight: fonts._700,
    fontSize: scale(9),
  },
  win: {
    alignItems: "center",
    marginHorizontal: scale(10),
  },
  bet: {
    marginTop: verticalScale(20),
  },
  indWon: {
    marginTop: verticalScale(45),
  },
  wonInd: {
    width: scale(13),
    height: scale(13),
    borderRadius: scale(100),
  },
  flatList: {},
  upicon: {
    height: scale(11),
    marginBottom: scale(3),
  },
  down: {
    height: scale(11),
    width: scale(8),
    marginTop: verticalScale(2.5),
  },
  liveBtn: {
    justifyContent: "center",
    alignItems: "center",
    width: scale(140),
    height: verticalScale(30),
    borderColor: "rgba(255,255,255,0.3)",
    borderWidth: scale(1),
    backgroundColor: colors.bg,
    // paddingTop: verticalScale(3),
  },
});

export default Match;
