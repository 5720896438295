import {
  View,
  StyleSheet,
  FlatList,
  TextInput,
  TouchableOpacity,
  ImageBackground,
  ScrollView,
  useWindowDimensions,
  Vibration,
} from "react-native";
import { useEffect, useState } from "react";
import { scale, verticalScale } from "react-native-size-matters";
import { useSelector } from "react-redux";
import moment from "moment";
import Modal from "./modal";

import Typography from "./typography";
import Img from "./image";
import BetConfirm from "./betConfirm";
import Loader from "./loader";

import icons from "../contants/icons";
import colors from "../contants/colors";
import fonts from "../contants/fonts";
import gifs from "../contants/gifs";

import api from "../functions/api";
import toast from "../functions/toast";

const Balls = ({ matchData, liveResult, x }) => {
  const [btns, setBtns] = useState([]);
  const [pre, setPre] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(0);
  const [nextTime, setNextTime] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [sm, setSm] = useState(false);
  const [gif, setGif] = useState(null);
  const [gifWidth, setGifWidth] = useState(scale(250));
  const [gifMT, setGifMT] = useState(0);
  const [waiting, setWaiting] = useState(true);
  const [prevResultId, setPrevResId] = useState(null);

  const user = useSelector((s) => s.user.value);

  const { width } = useWindowDimensions();

  const getButtons = async () => {
    try {
      const btns = await api.get("/bet-buttons");
      setBtns(btns);
    } catch (err) {
      console.log("🚀 ~ file: balls.js:25 ~ getButtons ~ err:", err);
    }
    setShowLoader(false);
  };

  const addBet = async (betAmount) => {
    setShowLoader(true);
    try {
      const data = {
        value: pre.value,
        betAmount,
        x: parseFloat(pre.x).toFixed(2),
        tournament: matchData.tournament,
        _match: matchData._id,
        team: 20,
        user,
      };
      await api.post("/add-bet", data);
      toast("Bet added successfully");
    } catch (err) {
      console.log("🚀 ~ file: balls.js:50 ~ addBet ~ err:", err);
    }
    setShowModal(false);
    setShowLoader(false);
  };

  const getTimer = async (id, f) => {
    const tm = new Date();

    tm.setSeconds(tm.getSeconds() + 30);

    setNextTime(tm);
  };

  function utc(d) {
    let localDate = new Date(d);
    var localYear = localDate.getFullYear();
    var localMonth = localDate.getMonth();
    var localDay = localDate.getDate();
    var localHours = localDate.getHours();
    var localMinutes = localDate.getMinutes();
    var localSeconds = localDate.getSeconds();

    // Create a new Date object using local time components
    var utcDate = new Date(
      localYear,
      localMonth,
      localDay,
      localHours,
      localMinutes,
      localSeconds
    );

    // Convert local time to UTC time
    var utcMilliseconds =
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000; // Convert to milliseconds
    var utcTime = new Date(utcMilliseconds);

    return utcTime;
  }

  const showGifPopup = (hideGif) => {
    if (matchData.status !== "Live") return;

    if (liveResult && liveResult[0]) {
      if (liveResult[0]._id === prevResultId) return;

      const prevDate = utc(liveResult[0].createdAt);
      // prevDate.setSeconds(prevDate.getSeconds() - 28);
      const curDate = utc(new Date());

      const timeDifference = curDate - prevDate;

      if (timeDifference > 3000) return;

      const [sp0, sp1] = liveResult[0].result.split(" + ");
      const res = sp1 || sp0;

      setGifMT(0);

      setGif(
        res === "Dott Ball"
          ? gifs.dottBall
          : res == 1
          ? gifs.one
          : res == 2
          ? gifs.two
          : res == 3
          ? gifs.three
          : res == 4
          ? gifs.fourRun
          : res == "Four"
          ? gifs.four
          : res == "Six"
          ? gifs.six
          : res == "WD"
          ? gifs.wd
          : res == "NB"
          ? gifs.nb
          : liveResult[0].wicket == 1
          ? gifs.wicket
          : null
      );

      setSm(true);

      const time =
        liveResult[0].wicket == 1
          ? 9000
          : res == "Six" || res == "Four"
          ? 4000
          : 2500;

      setGifWidth(
        res == "Six" || res == "Four" || liveResult[0].wicket == 1
          ? scale(250)
          : "100%"
      );

      setTimeout(() => {
        setGif(gifs.startStake);
        setGifWidth("100%");
        setGifMT(verticalScale(400));
        setTimeout(() => {
          setWaiting(false);
          setSm(false);
          setGifMT(0);
          getTimer(matchData._id);
          setGif(null);
          Vibration.vibrate(200);
        }, 3000);
      }, time);
      setPrevResId(liveResult[0]._id);
    }
  };

  const nextOver = (over, no) => {
    if (no) return over;

    if (!over) return false;

    let _over = parseInt(over.split(".")[0]);
    let _ball = parseInt(over.split(".")[1]);

    if (_ball === 5) {
      _over = _over + 1;
      _ball = 0;
    } else {
      _ball = _ball + 1;
    }

    return `${_over}.${_ball}`;
  };

  const isRun = (val) => {
    return (
      val == 1 ||
      val == 2 ||
      val == 3 ||
      val == 4 ||
      val == 5 ||
      val == 6 ||
      val == 7
    );
  };

  useEffect(() => {
    if (matchData) {
      getButtons();
    }
  }, [matchData]);

  // useEffect(() => {
  //   if (!sm && x) {
  //     const i = setInterval(() => {
  //       const utcFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
  //       console.log(new Date(x.time));

  //       const utcDate = moment.utc(new Date(x.time), utcFormat);
  //       const istDate = utcDate.local();
  //       const duration = moment.duration(istDate.diff(moment()));
  //       const timer = Math.floor(duration.asSeconds());

  //       setTimer(timer <= 0 ? 0 : timer);
  //     }, 1000);
  //     return () => clearInterval(i);
  //   }
  // }, [sm, x]);

  useEffect(() => {
    if (!sm && x?.time) {
      const i = setInterval(() => {
        const istDate = moment(x.time);
        const duration = moment.duration(istDate.diff(moment())); // Difference from now
        const timer = Math.floor(duration.asSeconds() + 20);

        setTimer(timer <= 0 ? 0 : timer);
      }, 1000);

      return () => clearInterval(i);
    }
  }, [sm, x]);

  // useEffect(() => {
  //   if (!sm && x) {
  //     const i = setInterval(() => {
  //       const utcDate = moment.utc(x.time); // Convert to UTC
  //       const istDate = utcDate.local(); // Convert to IST

  //       const duration = moment.duration(istDate.diff(moment()));

  //       setTimer(
  //         duration.asSeconds() <= 0 ? 0 : Math.floor(duration.asSeconds())
  //       );
  //     }, 1000);

  //     return () => clearInterval(i);
  //   }
  // }, [sm, x]);

  useEffect(() => {
    matchData && showGifPopup();
  }, [liveResult, matchData]);

  useEffect(() => {
    if (timer === 0 && matchData) {
      setGif(gifs.stopStake);
      setGifWidth("100%");
      setGifMT(verticalScale(400));
      setSm(true);
      setTimeout(() => {
        setSm(false);
        setGifMT(0);
        getTimer(matchData._id);
        setWaiting(true);
        setShowModal(false);
      }, 3000);
    }
  }, [timer]);

  return (
    <>
      <Modal isVisible={sm} backdropOpacity={0.7}>
        {gif && (
          <View
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={gif}
              style={{
                width: gifWidth,
                marginTop: gifMT,
                // height: gifWidth,
                zIndex: 999,
              }}
            />
          </View>
        )}
      </Modal>
      <Loader show={showLoader} />
      {liveResult && liveResult.length > 0 && x && (
        <ScrollView
          contentContainerStyle={styles.container}
          nestedScrollEnabled
          showsVerticalScrollIndicator={false}
        >
          <BetConfirm
            show={timer === 0 ? false : showModal}
            hideModal={() => setShowModal(false)}
            onChange={addBet}
            x={pre && parseFloat(pre.x).toFixed(2)}
            selected={pre && pre.title}
          />
          <View style={styles.runs}>
            <View style={styles.ball}>
              <Typography
                title={
                  liveResult[0].result +
                  (isRun(liveResult[0].result) ? " RUN" : "")
                }
                style={styles.run}
              />
              <Typography
                title={`Ball Result (${liveResult[0]._over})`}
                style={styles.balls}
              />
            </View>
            <View style={styles.ballOver}>
              <Typography title="Upcoming Ball Timer" style={styles.ballTime} />
              <Typography
                title={`Over (${nextOver(liveResult[0]._over)})`}
                style={styles.over}
              />
            </View>
            {/* <Img source={icons.time} style={styles.time} /> */}
            <ImageBackground
              source={icons.time}
              style={styles.time}
              resizeMode="cover"
            >
              <Typography
                title={timer}
                style={{ fontSize: scale(17), marginTop: verticalScale(12) }}
              />
            </ImageBackground>
          </View>
          <View
            style={{
              position: "relative",
              marginTop: verticalScale(10),
              pading: scale(10),
            }}
          >
            {waiting && timer === 0 && !sm && (
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 99,
                  borderRadius: scale(20),
                }}
              >
                <Img
                  source={gifs.wait}
                  style={{
                    width: width - scale(60),
                    height: verticalScale(40),
                    borderWidth: scale(1),
                  }}
                />
              </View>
            )}
            <FlatList
              data={x.buttons}
              numColumns={3}
              scrollEnabled={false}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={styles.flatList}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  style={{
                    ...styles.dotBall,
                    ...((timer === 0 ||
                      matchData.status != "Live" ||
                      item.locked) && {
                      backgroundColor: "rgba(0,0,0,0.5)",
                      opacity: 0.5,
                    }),
                  }}
                  onPress={() => {
                    setPre(item);
                    setShowModal(true);
                  }}
                  disabled={
                    timer === 0 || matchData.status != "Live" || item.locked
                  }
                >
                  <Typography
                    title={item.title}
                    style={{ fontSize: scale(11) }}
                  />
                  <Typography
                    title={`${item.x.toFixed(2)}X`}
                    style={{ fontSize: scale(13), marginTop: verticalScale(3) }}
                  />
                </TouchableOpacity>
              )}
            />
          </View>
          {/* <View style={styles.TextInput}>
            <TextInput
              placeholder="Set Stake Limit"
              placeholderTextColor={colors.white}
              style={styles.input}
            />
            <TouchableOpacity style={styles.btn}>
              <Typography title="Save" style={styles.btnText} />
            </TouchableOpacity>
          </View> */}
        </ScrollView>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: scale(20),
    paddingBottom: scale(30),
  },
  runs: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  run: {
    fontSize: scale(15),
    letterSpacing: scale(1),
    borderWidth: scale(1),
    borderColor: colors.white,
    borderRadius: scale(100),
    paddingHorizontal: scale(15),
    fontWeight: fonts._500,
    paddingVertical: verticalScale(4),
  },
  ball: {
    alignItems: "center",
    justifyContent: "center ",
  },
  balls: {
    fontSize: scale(11),
    fontWeight: fonts._500,
    letterSpacing: scale(1),
    marginTop: verticalScale(5),
  },
  time: {
    width: scale(40),
    height: scale(47),
    alignItems: "center",
    justifyContent: "center",
  },
  ballTime: {
    fontSize: scale(11),
    fontWeight: fonts._500,
    letterSpacing: scale(1),
  },
  over: {
    fontSize: scale(13),
    fontWeight: fonts._500,
    letterSpacing: scale(1),
    textAlign: "center",
    marginTop: verticalScale(4),
  },
  ballOver: {
    marginTop: verticalScale(10),
  },
  dotBall: {
    alignItems: "center",
    borderWidth: scale(1),
    borderColor: colors.btn,
    borderRadius: scale(5),
    // paddingHorizontal: 10,
    marginHorizontal: scale(5),
    marginVertical: verticalScale(5),
    paddingVertical: scale(10),
    width: scale(95),
  },
  flatList: {
    width: "100%",
    paddingBottom: verticalScale(15),
    alignItems: "center",
  },
  input: {
    backgroundColor: colors.primary,
    width: scale(200),
    height: verticalScale(40),
    borderRadius: scale(100),
    fontSize: scale(11),
    color: colors.white,
    paddingHorizontal: scale(15),
  },
  btn: {
    backgroundColor: colors.btn,
    paddingHorizontal: scale(15),
    paddingVertical: verticalScale(7),
    borderRadius: scale(200),
    justifyContent: "center",
    alignItems: "center",
    marginLeft: scale(15),
  },
  btnText: {
    fontSize: scale(12),
    fontWeight: fonts._600,
  },
  TextInput: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: verticalScale(20),
  },
});

export default Balls;
